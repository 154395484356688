import React, {Component} from 'react';
// import SectionTitle from '../SectionTitle';
import './OfferContent.css';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faLaptop, faCode, faSitemap, faTextWidth, faMagic, faUsers, faWifi, faHome,
  faCreditCard, faShieldAlt, faUserSecret, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { library, dom } from '@fortawesome/fontawesome-svg-core'

library.add(faLaptop, faCode, faSitemap, faTextWidth, faMagic, faUsers, faWifi, faHome, faCreditCard,
    faShieldAlt, faUserSecret, faThumbsUp);

dom.watch()

const title = "Nie musisz mieszkać na swoim,";
const subtitle = "żeby mieszkać po swojemu.";
const text = "Przygotujemy dla Ciebie pokój lub\n" +
    "mieszkanie jakiego potrzebujesz. Twoje potrzeby są dla nas najważniejsze, chcemy żebyś w\n" +
    "wynajmowanym mieszkaniu mógł się poczuć jak w domu, dlatego w każdym miejscu tworzymy\n" +
    "niepowtarzalną atmosferę. Niezależnie od budżetu jakim dysponujesz w każdym z naszych mieszkań\n" +
    "poczujesz się domowo i przytulnie, ponieważ jednym z naszych założeń jest tworzenie przestrzeni,\n" +
    "która umożliwi Ci rozwój, a nie będzie tylko smutnym miejscem do spania";

class OfferContent extends Component {
  render() {
    return (
        <div className="WrapperOffer">
          <div>
            <header className="TextModule">
              <h2>
                <span>{ title }</span>
              </h2>
              <h2>
                { subtitle }
              </h2>
              <p>{ text }</p>
              {/*<SectionTitle title={title} text={text}/>*/}
            </header>
          </div>
          <div className="GridContainer">
            <div>
              <div className="ColumnLeft">
                  <section className="wow fadeInLeft">
                    <div className="GridRow">
                      <div className="GridIcon">
                        <FontAwesomeIcon className="IconRight" icon={["fa", "home"]} />
                      </div>
                      <div className="TextModule">
                        <h3>Warunki mieszkaniowe</h3>
                        <p>niezależnie od ceny jaką płacisz za pokój lub mieszkanie, uważamy że każdy powinien mieszkać jak człowiek i mówimy stanowcze „nie" mieszkaniom, w których aż strach mieszkać.</p>
                      </div>
                    </div>
                    <div className="GridRow">
                      <div className="GridIcon">
                        <FontAwesomeIcon className="IconRight" icon={["fa", "shield-alt"]} />
                      </div>
                      <div className="TextModule">
                        <h3>Bezpieczna umowa najmu</h3>
                        <p>zapewni Ci komfort i bezpieczeństwo wynajmu</p>
                      </div>
                    </div>
                    <div className="GridRow">
                      <div className="GridIcon">
                        <FontAwesomeIcon className="IconRight" icon={["fa", "credit-card"]} />
                      </div>
                      <div className="TextModule">
                        <h3>Bezgotówkowe rozliczenia</h3>
                        <p>nigdy więcej niespodziewanych wizyt właściciela, który właśnie przyjechał po pieniądze.</p>
                      </div>
                    </div>
                  </section>
              </div>
              <div className="ColumnImage">
                <div className="Image">
                  <section className="wow fadeInUp">
                    <img alt="" src="phone-tulips.png" />
                  </section>
                </div>
              </div>
              <div className="ColumnRight">
                <section className="wow fadeInRight" >
                  <div className="GridRow">
                    <div className="GridIcon">
                      <FontAwesomeIcon className="IconLeft" icon={["fa", "thumbs-up"]} />
                    </div>
                    <div className="TextModule">
                      <h3>Regulamin mieszkania</h3>
                      <p>koniec z kłótniami o to, kto tym razem powinien posprzątać kuchnię. Jasne zasady to spokojne i bezproblemowe mieszkanie</p>
                    </div>
                  </div>
                  <div className="GridRow">
                    <div className="GridIcon">
                      <FontAwesomeIcon className="IconLeft" icon={["fa", "wifi"]} />
                    </div>
                    <div className="TextModule">
                      <h3>Internet</h3>
                      <p>to chyba oczywista oczywistość, prawda?</p>
                    </div>
                  </div>
                  <div className="GridRow">
                    <div className="GridIcon">
                      <FontAwesomeIcon className="IconLeft" icon={["fa", "user-secret"]} />
                    </div>
                    <div className="TextModule">
                      <h3>Agent od zadań specjalnych</h3>
                      <p>to opiekun mieszkania, przyjaciel i coacher w jednej osobie. Pomoże rozwiązać każdy problem mieszkaniowy, podpowie, gdzie kupić dobre warzywa czy gdzie warto zamówić pizzę.</p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
    );
  }
};

export default OfferContent;
