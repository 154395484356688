import React, {Component} from 'react';

class ContactForm extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     name: '',
  //     email: '',
  //     phone: '',
  //     message: '',
  //   };
  //   this.handleChange = this.handleChange.bind(this);
  //   this.handleSubmit = this.handleSubmit.bind(this);
  // }
  // handleChange(event) {
  //   const target = event.target;
  //   const value = target.type === 'checkbox' ? target.checked : target.value;
  //   const name = target.name;
  //
  //   this.setState({
  //     [name]: value
  //   });
  // }
  // handleSubmit(e){
  //   e.preventDefault();
  //   const { name, email, phone, message } = this.state;
  //
  //   fetch('http://localhost:8080/mail', {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       name: name,
  //       email: email,
  //       phone: phone,
  //       message: message
  //     }),
  //   })
  //   .catch((error) => {
  //     console.error(error);
  //   });
  // }
  // render() {
  //   return (
  //       <div className="ContactFormWrapper">
  //         <div className="ContentFormText">
  //           <h2>
  //             <span>
  //               Masz pytania? Chcesz się z nami spotkać i dowiedzieć więcej o wynajmie?
  //             </span>
  //           </h2>
  //         </div>
  //         <div className="ContentFormText">
  //           <h4>
  //             <span>
  //               Zapraszamy do kontaktu!
  //             </span>
  //           </h4>
  //         </div>
  //         <div className="Separator"></div>
  //         <form className="ContactForm" onSubmit={this.handleSubmit}>
  //           <div className="form-work">
  //             {/*<div class="row ">*/}
  //               <div className="form-group">
  //                 <div className="col">
  //                   <input
  //                       value={this.state.name}
  //                       onChange={this.handleChange}
  //                       className="form-control FormItem"
  //                       name="name"
  //                       type="text"
  //                       placeholder="Imię i nazwisko"
  //                       required
  //                   />
  //                   <input
  //                       value={this.state.email}
  //                       onChange={this.handleChange}
  //                       className="form-control FormItem"
  //                       name="email"
  //                       type="email"
  //                       placeholder="Adres email"
  //                       required
  //                   />
  //                   <input
  //                       value={this.state.phone}
  //                       onChange={this.handleChange}
  //                       className="form-control FormItem"
  //                       name="phone"
  //                       type="phone"
  //                       placeholder="Numer Telefonu"
  //                       required />
  //                 </div>
  //               </div>
  //             {/*</div>*/}
  //             <div className="form-group">
  //               <div className="col">
  //                 <textarea
  //                     value={this.state.message}
  //                     onChange={this.handleChange}
  //                     cols="40"
  //                     rows="8"
  //                     className="form-control FormItem"
  //                     name="message"
  //                     type="textarea"
  //                     placeholder="Wiadomość" />
  //               </div>
  //             </div>
  //             <div className="">
  //               <input className="ButtonBorder" type="submit" value="Wyślij"/>
  //             </div>
  //           </div>
  //         </form>
  //       </div>
  //   );
  // }
  render() {
    return (
        <div className="ContactFormWrapper">
          <div className="SeparatorContactForm"></div>
          <div className="ContentFormText">
            <h2>
              <span>
                Masz pytania?
              </span>
            </h2>
            <h2>
              <span>
               Chcesz się z nami spotkać?
              </span>
            </h2>
            <h2>
              <span>
               Dowiedzieć więcej o wynajmie?
              </span>
            </h2>
          </div>
          <div className="ContentFormText">
            <h2>
              <span>
                Zapraszamy do kontaktu!
              </span>
            </h2>
          </div>
          <div className="SeparatorContact"></div>
          <div className="ContentFormText">
            <h4>
              <span>
                mail: biuro@krakowskiepokoje.pl
              </span>
            </h4>
            <h2>
              <span>
                tel. 691802254
              </span>
            </h2>
            <p>
              Sławek i Klaudia Leszczyńscy
            </p>
          </div>
          {/*<form className="ContactForm" onSubmit={this.handleSubmit}>*/}
            {/*<div className="form-work">*/}
              {/*/!*<div class="row ">*!/*/}
              {/*<div className="form-group">*/}
                {/*<div className="col">*/}
                  {/*<input*/}
                      {/*value={this.state.name}*/}
                      {/*onChange={this.handleChange}*/}
                      {/*className="form-control FormItem"*/}
                      {/*name="name"*/}
                      {/*type="text"*/}
                      {/*placeholder="Imię i nazwisko"*/}
                      {/*required*/}
                  {/*/>*/}
                  {/*<input*/}
                      {/*value={this.state.email}*/}
                      {/*onChange={this.handleChange}*/}
                      {/*className="form-control FormItem"*/}
                      {/*name="email"*/}
                      {/*type="email"*/}
                      {/*placeholder="Adres email"*/}
                      {/*required*/}
                  {/*/>*/}
                  {/*<input*/}
                      {/*value={this.state.phone}*/}
                      {/*onChange={this.handleChange}*/}
                      {/*className="form-control FormItem"*/}
                      {/*name="phone"*/}
                      {/*type="phone"*/}
                      {/*placeholder="Numer Telefonu"*/}
                      {/*required />*/}
                {/*</div>*/}
              {/*</div>*/}
              {/*/!*</div>*!/*/}
              {/*<div className="form-group">*/}
                {/*<div className="col">*/}
                  {/*<textarea*/}
                      {/*value={this.state.message}*/}
                      {/*onChange={this.handleChange}*/}
                      {/*cols="40"*/}
                      {/*rows="8"*/}
                      {/*className="form-control FormItem"*/}
                      {/*name="message"*/}
                      {/*type="textarea"*/}
                      {/*placeholder="Wiadomość" />*/}
                {/*</div>*/}
              {/*</div>*/}
              {/*<div className="">*/}
                {/*<input className="ButtonBorder" type="submit" value="Wyślij"/>*/}
              {/*</div>*/}
            {/*</div>*/}
          {/*</form>*/}
        </div>
    );
  }
};

export default ContactForm;
