import React, {Component} from 'react';

class TextContent extends Component {
  render() {
    return (
        <div className="TextContentWrapper">
          <div className="TextModule">
            <h2>WITAJ NA STRONIE KRAKOWSKICH POKOJÓW</h2>
          </div>
          <div className="Separator"></div>
          <div className="TextModule">
            <p>Kim jesteśmy? Ludźmi, których łączą więzi rodzinne, przyjaźń i pasja. Codziennie wzajemnie się
              motywujemy, szukając coraz lepszych rozwiązań, które sprawią, że naszym najemcom będzie się
              mieszkać jak w domu, a właściciele będą spać spokojnie. Projekt „Krakowskie Pokoje" zrodził się z
              potrzeby serca, by zapewnić naszym mieszkańcom przestrzeń, w której żyje się pięknie i po prostu
              domowo. Bo to jak mieszkamy, wpływa na to jak się czujemy i jaką siłą do działania dysponujemy.
              Inspiracje znajdujemy dosłownie wszędzie: w podróżach, w przyrodzie, podczas rozmów, czy
              gotowania, to wszystko składa się na to jak tworzymy atmosferę naszych mieszkań. Nasze
              zainteresowania przekuliśmy w zupełnie nowy produkt, który łączy w sobie wynajem, zarządzanie
              nieruchomościami, pośrednictwo w sprzedaży, projektowanie wnętrz, homestaging i coaching dla
              studentów. Kochamy życie, dobre jedzenie i działanie. Nie czekamy, aż nasze marzenia same się
              spełnią. To my je spełniamy i zarażamy tym podejściem innych.</p>
          </div>
        </div>
    );
  }
};

export default TextContent;
