import React, { Component } from 'react';
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import Logo from './components/Logo/Logo';
import Navigation from './components/Navigation';
import Social from './components/SocialFooter';
import Header from './components/Header';
import './App.css';
import SliderImgCarusel from './components/SliderImgCarusel';
import TextContent from './components/TextContent';
import Footer from './components/Footer';
import ContactForm from './components/ContactForm';
import ScrollableAnchor from 'react-scrollable-anchor'
import OfferContent from './components/Offer/OfferContent';
import WOWJS from 'wowjs';
import FacebookMessangerPlugin from './components/FacebookMessangerPlugin';
import RoomsGallery from './components/RoomsGallery/RoomsGallery';
import BackgroundSlider from './components/BackgroundSlider';
import OfferOwner from "./components/OfferOwner";
import Homestaging from "./components/Homestaging";
import InstagramSlider from './components/InstagramSlider';
// import BackToTop from './components/BackToTop';
// import { FacebookProvider, Group, Page } from 'react-facebook';

const imageBackground = {
  backgroundImage: 'url(./images/contact-background5.jpg)'
};


class App extends Component {


  componentDidMount() {
    new WOWJS.WOW({
      live: false
    }).init();
  }

  render() {
    return (
      <div className="App">
        <FacebookMessangerPlugin />
        {/*<BackToTop />*/}
        {/*<FacebookProvider appId="2031463177111034">*/}
          {/*<Page href="https://www.facebook.com/krakowskiepokoje/" />*/}
        {/*</FacebookProvider>*/}
        <div className="NavContent">
          <aside id="nav" className="Nav" tabIndex="5000" >
            <div className="FixedContent" />
            <div className="ScrollContent">
              <section className="wow fadeIn">
                <section className="wow pulse" data-wow-delay="2s" data-wow-iteration="2">
                  <Logo />
                </section>
                <Navigation />
                <Social />
              </section>
            </div>
          </aside>
        </div>
        <div>
          <Header />
        </div>
        <div className="Content">
          <div className="ContentInner">
            <div />
            <section className="wow fadeInDownBig">
              <div className="SliderContent">
                <ScrollableAnchor id="home">
                  <div>
                    <BackgroundSlider />
                  </div>
                </ScrollableAnchor>
              </div>
            </section>
            <section>
              <div className="TextContent">
                <ScrollableAnchor id="about">
                  <TextContent />
                </ScrollableAnchor>
              </div>
            </section>
            <div className="OfferOwnerContent">
              <section className="wow fadeIn">
                <ScrollableAnchor id="owner">
                  <OfferOwner />
                </ScrollableAnchor>
              </section>
            </div>
            <div className="SliderContentImgCarusel">
              <div>
                <section className="wow fadeIn" data-wow-delay="0.5s">
                  <SliderImgCarusel />
                </section>
              </div>
            </div>
            <div className="HomestagingContent">
              <section className="wow fadeIn">
                <ScrollableAnchor id="homestaging">
                  <Homestaging />
                </ScrollableAnchor>
              </section>
            </div>
            <div className="RoomsGallery">
              <ScrollableAnchor id="rooms">
                <RoomsGallery />
              </ScrollableAnchor>
            </div>
            <div className="OfferContent">
              <ScrollableAnchor id="offer">
                <OfferContent />
              </ScrollableAnchor>
            </div>
            <div className="ContactFormContent" style={imageBackground}>
              <ScrollableAnchor id="contact">
                <ContactForm />
              </ScrollableAnchor>
              {/*<div >*/}
                {/*<FacebookProvider appId="2031463177111034">*/}
                  {/*<Group*/}
                      {/*href="https://www.facebook.com/groups/kreatorkiwynajmu/?source_id=1510004172348749"*/}
                      {/*width="300"*/}
                      {/*showSocialContext={true}*/}
                      {/*showMetaData={true}*/}
                      {/*skin="light"*/}
                  {/*/>*/}
                {/*</FacebookProvider>*/}
              {/*</div>*/}
            </div>
            <div className="FooterContent">
              <Footer />
            </div>
            <div>
              <InstagramSlider />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
