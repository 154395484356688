import React from 'react';
import './SectionText.css';

export default (props) => {
    return (
        <div className="SectionTitleWrapper">
          <div className="TextModule">
            <h2>{ props.title }</h2>
          </div>
          <div className="Separator"></div>
          <div className="SectionTitleWrapperText">
            <h3> { props.subheading } </h3>
          </div>
          <div className="Separator"></div>
          <div className="SectionTitleWrapperText">
            <p> { props.text } </p>
          </div>
        </div>
    );
};
