import React, { Component } from 'react';
import Modal from 'react-responsive-modal';


export default class Privacy extends Component {

  state = {
    open: false,
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
        <div>
          <span>Krakowskie Pokoje. Wszystkie prawa zastrzeżone. <a onClick={this.onOpenModal} href="#">Polityka Prywatności.</a> </span>
          <Modal open={open} onClose={this.onCloseModal} center>
            <div>
              <h2>Polityka prywatności i plików cookies</h2>

              <p>
                Polityka prywatności i plików cookies opisuje zasady postępowania z danymi osobowymi oraz wykorzystywania plików cookies i innych technologii w ramach strony internetowej <a href="http://www.krakowskiepokoje.pl/">krakowskiepokoje.pl</a> . Korzystanie ze strony internetowej oznacza akceptację poniższych warunków Polityki Prywatności i Polityki Cookies.
              </p>
              <p>
                Administratorem strony i danych osobowych jest Sławomir Leszczyński, zamieszkała pod adresem: ul. Dąbska 18K/29, 31-572 Kraków. Kontakt z administratorem możliwy pod adresem e-mail: s.leszczynski@krakowskiepokoje.pl
              </p>
              <h3>1. Definicje</h3>
              <p>Na potrzeby niniejszej polityki prywatności, pojęcia zapisane wielką literą przyjmują znaczenia nadane im poniżej:</p>
              <p>Administrator – Sławomir Leszczyński, zamieszkała pod adresem: ul. Dąbska 18K/29, 31-572 Kraków</p>
              <p>Strona – strona internetowa dostępna pod adresem: <a href="http://www.krakowskiepokoje.pl/" alt="Krakowskie Pokoje">krakowskiepokoje.pl</a></p>
              <p>Użytkownik – każdy podmiot, który korzysta ze Strony.</p>
              <p>“Fanpage" lub “Grupa na Facebooku" –grupa “Krakowskie Pokoje" https://www.facebook.com/groups/kreatorkiwynajmu  których administratorem na platformie Facebook jest Sławomir Leszczyński i/lub osoby przez nią mianowane (upoważnione) do tej funkcji.</p>
              <p>„Newsletter" lub „usługa Newsletter" – oznacza bezpłatną usługę świadczoną drogą elektroniczną przez Administratora na rzecz Użytkownika poprzez przesyłanie listów elektronicznych, za pośrednictwem których Administrator informuje o wydarzeniach, usługach, produktach i innych elementach istotnych z punktu widzenia Administratora i /lub w celu realizacji prawnie uzasadnionego interesu Administratora np. marketingu produktów i usług własnych lub polecanych przez Administratora.</p>
              <p>„Umowa usługi Newsletter"  lub „Umowa Newsletter" – oznacza umowę pomiędzy Administratorem a Użytkownikiem, której przedmiotem jest świadczenie nieodpłatnej usługi Newsletter. W celu jej zawarcia należy wypełnić interaktywny formularz, zaznaczyć checkboxy a następnie potwierdzić zapis w e-mailu potwierdzającym otrzymanym drogą elektroniczną.</p>
              <h3>2. Dane osobowe</h3>
              <ol>
                <li>
                  Użytkownik może przekazywać swoje dane osobowe Administratorowi za pomocą formularzy dostępnych na Stronie, takich jak formularz zapisu na newsletter.
                </li>
                <li>
                  Administratorem danych osobowych Użytkownika jest Administrator.
                </li>
                <li>
                  Podanie danych jest dobrowolne, jednakże nie podanie pewnych informacji, co do zasady zaznaczonych na stronach Administratora jako obowiązkowe, wiązać się będzie z brakiem możliwości wykonania danej usługi i osiągnięcia określonego celu.
                </li>
                <li>
                  Zbierane i przetwarzane są tylko te dane, które użytkownik sam poda (za wyjątkiem – w pewnych sytuacjach – danych zbieranych automatycznie za pomocą plików cookies oraz danych logowania, o czym mowa poniżej). Podczas wizyty na stronie, automatycznie zbierane są dane dotyczące samej wizyty, np. adres IP użytkownika, nazwa domeny, typ przeglądarki, typ systemu operacyjnego, itp. (dane logowania). Dane zbierane automatycznie mogą być użyte do analizy zachowań użytkowników na stronie internetowej, zbierania danych demograficznych o użytkownikach, lub do personalizacji zawartości strony w celu jej ulepszenia. Dane te przetwarzane są jednak wyłącznie w celach administrowania stroną i zapewnienia sprawnej obsługi hostingowej i nie są kojarzone z danymi poszczególnych Użytkowników.
                </li>
                <li>
                  Administrator gwarantuje poufność wszelkich przekazanych mu danych osobowych. Nie udostępnia on przekazanych mu danych jakimkolwiek podmiotom trzecim.
                </li>
                <li>
                  Dane osobowe są gromadzone z należytą starannością i odpowiednio chronione przed dostępem do nich przez osoby do tego nieupoważnione.
                </li>
                <li>
                  Dane osobowe podawane przez Użytkownika są przechowywane do momentu cofnięcia zgody na przetwarzanie danych lub przez czas prowadzenia przeze mnie strony krakowskiepokoje.pl i wysyłania newslettera.
                </li>
                <li>
                  Użytkownikowi przysługują następujące uprawnienia: prawo do żądania dostępu do swoich danych, ich sprostowania, usunięcia lub ograniczenia przetwarzania; prawo do wniesienia sprzeciwu wobec przetwarzania; prawo do przenoszenia danych; prawo do cofnięcia zgody na przetwarzanie danych osobowych w określonym celu, jeżeli Użytkownik uprzednio taką zgodę wyraził; prawo do wniesienia skargi do organu nadzorczego w związku z przetwarzaniem danych osobowych przez Administratora. Przysługuje mu też prawo do bycia zapomnianym, jeśli dalsze przetwarzanie nie będzie przewidziane przez aktualnie obowiązujące przepisy prawa. W tych celach może zwrócić się do Administratora poprzez adres e-mail: s.leszczynski@krakowskiepokoje.pl. Zgoda Użytkownika może być w każdym czasie odwołana, cofnięta, co skutkować będzie usunięciem adresu e-mail z listy mailingowej Administratora. Cofnięcie zgody pozostaje bez wpływu na przetwarzanie danych, którego dokonano na podstawie zgody przed jej cofnięciem.
                </li>
                <li>
                  Użytkownik wyraża zgodę na przetwarzanie podanych danych osobowych oraz na otrzymywanie informacji marketingowych środkami komunikacji elektronicznej od Administratora w celu wysyłania newslettera, kontaktu z Użytkownikiem lub wysyłania innych informacji marketingowych, np. o wydarzeniach lub produktach online oferowanych przez Administratora, jeżeli wyraził w tych celach odpowiednią zgodę i/lub jeżeli mieści się to w granicach prawnie uzasadnionego interesu Administratora.
                </li>
                <li>
                  Dane Użytkownika nie będą przekazywane poza obszar Unii Europejskiej, do państw trzecich, a jeśli dojdzie do takiej sytuacji to będą one przekazywanie wyłącznie odbiorcom, którzy przystąpili do porozumienia „Tarcza prywatności – Privacy Shield". Z uwagi na korzystanie z usług Google dane Użytkownika mogą być przekazywane do Stanów Zjednoczonych Ameryki (USA) w związku z ich przechowywaniem na amerykańskich serwerach. Google przystąpił do programu Privacy Shield i w ten sposób gwarantuje odpowiedni poziom ochrony danych osobowych wymagany przez przepisy prawa Unii Europejskiej.
                </li>
                <li>
                  Podanie przez Użytkownika danych, które nie są obowiązkowe lub nadmiaru danych, których Administrator nie potrzebuje przetwarzać następuje na podstawie decyzji samego Użytkownika i wówczas przetwarzanie odbywa się na podstawie przesłanki zawartej w art. 6 ust. 1 lit. a) RODO (zgoda). Użytkownik udziela zgody na przetwarzanie tych danych oraz na zanonimiowanie danych, których Administrator nie wymaga i nie chce przetwarzać, a mimo to Użytkownik przekazał je Administratorowi;
                </li>
                <li>
                  Dane osobowe przekazywane przez Użytkownika w ramach funkcjonowania Strony internetowej i bloga https://krakowskiepokoje.pl , fanpage’a “Krakowskie Pokoje" https://www.facebook.com/krakowskiepokoje mogą być przetwarzane w następujących celach i na następujących podstawach prawnych:
                </li>
                <ul>
                  <li>
                    dokonania wyceny usługi i wykonania usługi czy umowy — na podstawie art. 6 ust. 1 lit. b RODO (niezbędność do zawarcia i/lub wykonania umowy);
                  </li>
                  <li>
                    wystawienia faktury i spełnienia innych obowiązków wynikających z przepisów prawa podatkowego — na podstawie art. 6 ust. 1 lit. c RODO (obowiązek wynikający z przepisów prawa);
                  </li>
                  <li>
                    udzielenia rabatu lub kodu promocyjnego — na podstawie art. 6 ust. 1 lit. a) RODO (zgoda);
                  </li>
                  <li>
                    rozpatrzenia reklamacji czy roszczeń — na podstawie art. 6 ust. 1 lit. b RODO (niezbędność do zawarcia i/lub wykonania umowy);
                  </li>
                  <li>
                    ustalenia, dochodzenia lub obrony przed roszczeniami — na podstawie art. 6 ust. 1 lit. f) RODO (prawnie uzasadniony interes administratora);
                  </li>
                  <ul>
                    kontaktu telefonicznego w sprawach związanych z realizacją usługi — na podstawie art. 6 ust. 1 lit. a RODO (zgoda);
                  </ul>
                  <ul>
                    przechowywania nieopłaconych zamówień — na podstawie art. 6 ust. 1 lit. f) RODO (prawnie uzasadniony interes administratora);
                  </ul>
                  <ul>
                    tworzenia rejestrów i ewidencji związanych z RODO — na podstawie art. 6 ust. 1 lit. c) RODO (obowiązek wynikający z przepisów prawa) oraz art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes administratora);
                  </ul>
                  <ul>
                    archiwalnym i dowodowym, na potrzeby zabezpieczenia informacji, które mogą służyć wykazywaniu faktów — na podstawie art. 6 ust. 1 lit. f) RODO (prawnie uzasadniony interes administratora);
                  </ul>
                  <ul>
                    analitycznym, polegającym między innymi na analizie danych zbieranych automatycznie przy korzystaniu ze strony internetowej, w tym cookies np. cookies Google Analytics, Pixel Facebooka— na podstawie art. 6 ust. 1 lit. f) RODO (prawnie uzasadniony interes administratora);
                  </ul>
                  <ul>
                    wykorzystywania cookies na Stronach Administratora — na podstawie art. 6 ust. 1 lit. a) RODO (zgoda);
                  </ul>
                  <ul>
                    zarządzanie stroną internetową, grupami i stronami administratora na innych platformach— na podstawie art. 6 ust. 1 lit. f) RODO (prawnie uzasadniony interes administratora);
                  </ul>
                  <ul>
                    zamieszczenia komentarzy na Stronach Administratora — na podstawie art. 6 ust. 1 lit. a) RODO (zgoda);
                  </ul>
                  <ul>
                    zamieszczenia przez Użytkownika opinii o usługach świadczonych przez Administratora — na podstawie art. 6 ust. 1 lit. a) RODO (zgoda),
                  </ul>
                  <ul>
                    badania satysfakcji z oferowanych usług — na podstawie art. 6 ust. 1 lit. f) RODO (prawnie uzasadniony interes administratora);
                  </ul>
                </ul>
                <li>
                  Administrator informuje niniejszym Użytkownika, że powierza przetwarzanie danych osobowych następującym podmiotom:
                </li>
                <ul>
                  <li>
                    Home.pl S.A. z siedzibą w Szczecinie przy ul. Zbożowej 4, 70-653 Szczecin – w celu przechowywania danych osobowych na serwerze;
                  </li>
                  <li>
                    UAB “MailerLite", Paupio st. 46, LT-11341 Vilnius, Lithuania – w celu korzystania z systemu mailingowego, służącego do przesyłania newslettera
                  </li>
                  <li>
                    Google LLC – w celu korzystania z usług Google, w tym poczty e-mail
                  </li>
                  <li>
                    zleceniobiorcom lub podwykonawcom zaangażowanym do obsługi technicznej, administracyjnej, lub do świadczenia pomocy prawnej na rzecz klientów Administratora.
                  </li>
                </ul>
                <li>
                  Na Stronie mogą pojawiać się linki odsyłające do innych stron internetowych. Będą one otwierać się w nowym oknie przeglądarki lub w tym samym oknie. Administrator nie odpowiada za treści przekazywane przez te strony. Użytkownik zobowiązany jest do zapoznania się z polityką prywatności lub regulaminem tych stron.
                </li>
                <li>
                  Dane osobowe Użytkownika są przechowywane i chronione z należytą starannością, zgodnie z wdrożonymi procedurami wewnętrznymi Administratora. Administrator przetwarza informacje o Użytkowniku przy użyciu odpowiednich środków technicznych i organizacyjnych spełniających wymagania przepisów prawa powszechnie obowiązującego. Środki te mają na celu przede wszystkim zabezpieczenie danych osobowych Użytkowników przed dostępem osób nieupoważnionych. W szczególności, dostęp do danych osobowych Użytkowników mają jedynie osoby upoważnione, które są zobowiązane do zachowania tych danych w tajemnicy.
                </li>
                <li>
                  Podmioty, którym Administrator powierza przetwarzanie danych osobowych gwarantują wypełnianie obowiązków nałożonych przez RODO i stosowanie odpowiednich środków ochrony i bezpieczeństwa danych osobowych.
                </li>
                <li>
                  Administrator Danych Osobowych niniejszym informuje, że nie powołał Inspektora Ochrony Danych Osobowych (IODO) i wykonuje samodzielnie obowiązki związane z przetwarzaniem danych osobowych.
                </li>
                <li>
                  Użytkownik przyjmuje do wiadomości, iż jego dane osobowe mogą być przekazywane uprawnionym organom państwowym w związku z prowadzonymi przez nie postępowaniami, na ich żądanie i po spełnieniu przesłanek potwierdzających niezbędność pozyskania od nas tych danych.
                </li>
              </ol>
              <h3>3. Pliki cookies i inne technologie</h3>
              <ol>
                <li>
                  Administrator wykorzystuje pliki cookies (ciasteczka), czyli niewielkie informacje tekstowe, przechowywane na urządzeniu końcowym Użytkownika (np. komputerze, tablecie, smartfonie). Cookies mogą być odczytywane przez system teleinformatyczny Administratora.
                </li>
                <li>
                  Administrator przechowuje pliki cookies na urządzeniu końcowym Użytkownika, a następnie uzyskuje dostęp do informacji w nich zawartych w celach statystycznych, w celach marketingowych (remarketing) oraz w celu zapewnienia prawidłowego działania Strony.
                </li>
                <li>
                  Administrator informuje niniejszym Użytkownika, że istnieje możliwość takiej konfiguracji przeglądarki internetowej, która uniemożliwia przechowywanie plików cookies na urządzeniu końcowym Użytkownika. W takiej sytuacji, korzystanie ze Strony przez Użytkownika może być utrudnione.
                </li>
                <li>
                  Administrator wskazuje niniejszym, że pliki cookies mogą być przez Użytkownika usunięte po ich zapisaniu przez Administratora, poprzez odpowiednie funkcje przeglądarki internetowej, programy służące w tym celu lub skorzystanie z odpowiednich narzędzi dostępnych w ramach systemu operacyjnego, z którego korzysta Użytkownik.
                </li>
                <li>
                  Administrator informuje niniejszym Użytkownika, że stosuje następujące technologie śledzące działania podejmowane przez Użytkownika w ramach Strony oraz zbierające pliki cookies:
                </li>
                <ul>
                  <li>
                    <b>kod śledzenia Google Analytics</b> – w celu analizy statystyk Strony; Korzystanie z usług Google Analytics wiąże się z zaimplementowaniem w kodzie Strony udostępnionego przez Google kodu śledzącego (tzw. tracking code). Kod ten bazuje na plikach cookies, ale może wykorzystywać również inne technologie śledzące; zbierane w tym zakresie informacje są całkowicie anonimowe i nie pozwalają na identyfikację użytkownika.
                  </li>
                  <li>
                    <b>kontakt e-mailowy</b> – Użytkownik kontaktując się z Administratorem za pośrednictwem poczty elektronicznej, przekazuje adres e-mail jako adres nadawcy wiadomości. Wiadomość może także zawierać inne dane osobowe. Podstawą prawną przetwarzania danych osobowych w tej sytuacji jest zgoda Użytkownika wynikająca z zainicjowania kontaktu z Administratorem. Dane osobowe przekazywane Administratorowi poprzez wiadomość e-mail przetwarzane są wyłącznie w celu kontaktu. Treść korespondencji może podlegać archiwizacji. Korespondencja przechowywana jest na serwerze skrzynki pocztowej.
                  </li>
                  <li>
                    <b>afiliacja</b> – na Stronie mogą pojawić się reklamy podmiotów trzecich w postaci linków afiliacyjnych, bannerów afiliacyjnych. W celu prawidłowego działania programu afiliacyjnego, na Stronie mogą  wystąpić pliki cookies następujących firm: Convertiser, Ceneo, Mailer Lite, Webe Partners, Hurom.
                  </li>
                  <li>
                    <b>Facebook Pixel.</b> Korzystam z narzędzi marketingowych dostępnych w ramach serwisu Facebook i zapewnianych przez Facebook Inc., 1601 S. California Ave. Palo Alto, CA 94304, USA. W ramach tych narzędzi mogę kierować do Ciebie reklamy w serwisie Facebook dotyczące Strony
                  </li>
                </ul>
              </ol>
              <h3>4. Newsletter</h3>
              <ol>
                <li>Za pośrednictwem Strony Użytkownik może zapisać się do newslettera zawierającego informacje o nowościach, promocjach, produktach i usługach związanych z aktywnością Administratora w Internecie. W celu przesyłania newslettera, dane Użytkownika są przetwarzane w następującym zakresie: imię i adres e-mail.</li>
                <li>Akceptacja niniejszej Polityki prywatności i Polityki Cookies jest dobrowolna, ale konieczna do korzystania z usługi Newsletter.</li>
                <li>Zawarcie umowy Newsletter jest możliwe w każdym momencie, poprzez wypełnienie istniejących na stronie aktywnych formularzy Newsletter. Administrator świadczy usługę Newsletter bezpłatnie.</li>
                <li>Podstawą prawną przetwarzania danych osobowych Użytkownika w przypadku wysyłki newslettera jest umowa o świadczenie nieodpłatnej usługi drogą elektroniczną, której zawarcie jest równoznaczne z akceptacją regulaminu newslettera warunkującą zapis na newsletter. Regulamin przewiduje możliwości rezygnacji z newslettera.</li>
                <li>Dane przetwarzane w związku z wysyłką newslettera przetwarzane są przez czas obowiązywania umowy o świadczenie nieodpłatnej usługi drogą elektroniczną. Rezygnacja z newslettera równoznaczna z rozwiązaniem umowy powoduje usunięcie danych Użytkownika z bazy Administratora.</li>
                <li>Użytkownik jest uprawniony do wypowiedzenia umowy Newsletter w każdej chwili poprzez wypisanie się z listy subskrybentów Administratora. Dokona tego przez kliknięcie w wiadomości e-mail otrzymanej od Administratora w link dezaktywacyjny np. o treści „WYPISUJĘ SIĘ". Może też zrobić to w inny dogodny dla niego sposób, np. poprzez kontakt z Administratorem. </li>
              </ol>
              <h3>5. Kontakt mailowy</h3>
              <p>
                Kontaktując się z Administratorem za pośrednictwem udostępnionego przez niego na Stronie adresu poczty elektronicznej, Użytkownik przekazuje Administratorowi swój adres e-mail jako adres nadawcy wiadomości. Ponadto, może on w treści wiadomości przekazać Administratorowi także inne dane osobowe.  W takim przypadku podstawą przetwarzania danych osobowych Użytkownika jest jego zgoda dorozumiana poprzez zainicjowanie przez niego kontaktu. Dane osobowe Użytkownika przekazywane Administratorowi w ramach kontaktu mailowego przetwarzane są wyłącznie w celu obsługi zapytania Użytkownika.
              </p>
              <h3>6. Logi serwera</h3>
              <ol>
                <li>Korzystanie ze Strony wiąże się z przesyłaniem zapytań do serwera, na którym przechowywana jest Strona.</li>
                <li>Każde zapytanie skierowane do serwera zapisywane jest w logach serwera. Logi obejmują m.in. adres IP Użytkownika, datę i czas serwera, informacje o przeglądarce internetowej i systemie operacyjnym, z jakiego korzysta Użytkownik.</li>
                <li>Logi zapisywane i przechowywane są na serwerze.</li>
                <li>Dane zapisane w logach serwera nie są kojarzone z konkretnymi osobami korzystającymi ze Strony i nie są wykorzystywane przez Administratora w celu identyfikacji Użytkownika.</li>
                <li>Logi serwera stanowią wyłącznie materiał pomocniczy służący do administrowania Stroną, a ich zawartość nie jest ujawniana nikomu poza osobami upoważnionymi do administrowania serwerem.</li>
              </ol>
              <p><b>UWAGA: Informacje na stronie  są wyłącznie wyrazem osobistych poglądów autora i nie stanowią „rekomendacji" w rozumieniu przepisów ani indywidualnej porady. Wszelkie decyzje i działania podejmujesz na własną odpowiedzialność.</b></p>
            </div>
          </Modal>
        </div>
    )
  }
};


