import React, {Component} from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {faBars} from '@fortawesome/free-solid-svg-icons';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import MenuList from './MenuList';

library.add(faBars);
dom.watch();

class Header extends Component {
  render() {
    return (
        <header id="header" className="Header">
          <nav className="navbar-expand-md  bg-white">
            <a href="http://krakowskiepokoje.pl"><img className="logo-navbar-mobile" src="./logo-mobile.png" alt=""/></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <FontAwesomeIcon className="AwesomeIcon" icon="bars" />
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <MenuList />
            </div>
          </nav>
        </header>
    );
  }
};

export default Header;
