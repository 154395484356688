import React, {Component} from 'react';
// import Image from "../Image";
// import './PhotoGallery.css';
import Gallery from 'react-photo-gallery';
import LightBox from 'react-images';

// const info = "";

// class Gallery extends Component {
//   render() {
//     return (
//         <div className="SectionGallery">
//           <article className="Gallery">
//             <Image src={'./images/rooms/IMG_7647.jpg'} target="1" information={info}/>
//             <Image src={'./images/rooms/IMG_7543.jpg'} target="2" information={info} />
//             <Image src={'./images/rooms/IMG_0001v4.jpg'} target="3" information={info} />
//             <Image src={'./images/rooms/IMG_6299.jpg'} target="4" information={info} />
//           </article>
//           <article className="Gallery">
//             <Image src={'./images/rooms/IMG_0342.jpg'} target="5" information={info} />
//             <Image src={'./images/rooms/IMG_0001.jpg'} target="6" information={info} />
//             <Image src={'./images/rooms/IMG_7565.jpg'} target="7" information={info} />
//             <Image src={'./images/rooms/IMG_8361.jpg'} target="8" information={info} />
//           </article>
//         </div>
//     );
//   }
// };

const photos = [
  {
    src: './images/rooms/IMG_7647.jpg',
    width: 4,
    height: 3
  },
  {
    src: './images/rooms/IMG_7543.jpg',
    width: 1,
    height: 1
  },
  {
    src: './images/rooms/IMG_0001v4.jpg',
    width: 4,
    height: 3
  },
  {
    src: './images/rooms/IMG_6299.jpg',
    width: 1,
    height: 1
  },
  {
    src: './images/rooms/IMG_0342.jpg',
    width: 4,
    height: 3
  },
  {
    src: './images/rooms/IMG_0001.jpg',
    width: 1,
    height: 1
  },
  {
    src: './images/rooms/IMG_7565.jpg',
    width: 4,
    height: 3
  },
  {
    src: './images/rooms/IMG_8361.jpg',
    width: 1,
    height: 1
  },
  {
    src: './images/rooms/nowy-kant.jpg',
    width: 4,
    height: 3
  }
];

class PhotoGallery extends Component {
  constructor() {
    super();
    this.state = { currentImage: 0 };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  render() {
    return (
        <div>
          <Gallery photos={photos} onClick={this.openLightbox}  />
          <LightBox images={photos}
                    onClose={this.closeLightbox}
                    onClickPrev={this.gotoPrevious}
                    onClickNext={this.gotoNext}
                    currentImage={this.state.currentImage}
                    isOpen={this.state.lightboxIsOpen}
          />
        </div>
    )
  }
}

export default PhotoGallery;
