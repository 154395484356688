import React, {Component} from 'react';
import './Homestaging.css';
// import { AwesomeButton } from "react-awesome-button";
// import AwesomeButtonStyles from "react-awesome-button/src/styles/styles.scss";

class Homestaging extends Component {
  render() {
    return (
        <div className="WrapperHomestaging">
          <section className="wow fadeInLeft">
            <div className="TextModule">
              <h2>
                <span>Home Staging</span>
              </h2>
              <div className="TextModuleBox">
                <h3>Dla kogo jest nasza oferta Home Stagingu?</h3>
                <ul>
                  <li>Dla osób, które chcą sprzedać lub wynająć swoje mieszkanie powyżej ceny rynkowej. Wszystkie mieszkania, które przygotowywaliśmy do tej pory wynajmowały się ok. 20-30% powyżej średniej ceny – zarówno te wynajmowane w całości, jak i na pokoje.</li>
                  <li>Dla osób, które cenią swój czas  – dzięki Homestagingowi unikniesz wielokrotnego prezentowania mieszkania osobom, które nie są w stanie wyobrazić sobie jego potencjału i z tego powodu rezygnują po wizycie z zakupu.</li>
                  <li>Dla osób świadomych finansowo – Home Staging oznacza wyższą cenę oraz szybsze sfinalizowanie transakcji, dzięki czemu nie tracisz pieniędzy na utrzymywanie pustego mieszkania oraz korzyści finansowych, które mógłbyś osiągnąć, gdyby Twoje mieszkanie było już sprzedane lub wynajęte. </li>
                </ul>
              </div>
              <div className="TextModuleBox">
                <h3>Dla kogo nie jest nasza oferta Home Stagingu?</h3>
                <ul>
                  <li>Dla osób, które nie chcą inwestować ani złotówki w zmiany wyglądu mieszkania.</li>
                  <li>Dla osób, dla których ważniejszy jest własny sentyment do mieszkania niż możliwa stopa zwrotu z inwestycji w niego.</li>
                </ul>
              </div>
              <div className="TextModuleBox">
                <h3>Kreatorki Wynajmu</h3>
                <p className="EbookLeft">
                  <p>Nie zawsze masz czas i możliwości, żeby być na szkoleniu stacjonarnym, prawda? Dzięki moim produktom dowiesz się jak szybko i drogo sprzedać lub wynająć mieszkanie, jak samemu zrobić Home Staging, jak skutecznie prezentować mieszkanie, czy też jak właściwie rozliczać rachunki na wynajmie.</p>
                 {/*<p> <AwesomeButton*/}
                      {/*cssModule={AwesomeButtonStyles}*/}
                      {/*type="primary"*/}
                      {/*href="https://kreatorkiwynajmu.pl/fragment-e-booka-home-staging/"*/}
                      {/*target="_blank"*/}
                  {/*>*/}
                    {/*KUP E-BOOKA*/}
                  {/*</AwesomeButton>*/}
                  {/*</p>*/}
                  {/*<p>*/}
                  {/*<AwesomeButton*/}
                      {/*cssModule={AwesomeButtonStyles}*/}
                      {/*type="primary"*/}
                      {/*href="https://kreatorkiwynajmu.pl/fragment-e-booka-home-staging/"*/}
                      {/*target="_blank"*/}
                  {/*>*/}
                    {/*POBIERZ DARMOWY FRAGMENT*/}
                  {/*</AwesomeButton>*/}
                  {/*</p>*/}
                </p>
                <p className="EbookRight"> <a href="https://sklep.kreatorkiwynajmu.pl/produkt/ebook/" target="_blank" rel="noopener noreferrer"><img title='Home Staging Ebook' alt='Home Staging Ebook' src='home staging ebook.jpg' information='Klub Kreatorek Wynajmu' /></a></p>
              </div>
              <div className="TextModuleBox">
                <h3>Klub Kreatorki Wynajmu</h3>
                <p className="banerKreatorki">
                  <a href="https://www.facebook.com/groups/kreatorkiwynajmu/" target="_blank" rel="noopener noreferrer"><img title='Klub Kreatorek Wynajmu' alt='Klub Kreatorek Wynajmu' src='klub-kreatorek.png' information='Klub Kreatorek Wynajmu' /></a>
                </p>
              </div>
            </div>
          </section>
        </div>
    );
  }
};

export default Homestaging;
