import React from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faFacebook, faFacebookMessenger, faFacebookF, faGooglePlus,
        faInstagram, faPinterest, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { library, dom } from '@fortawesome/fontawesome-svg-core';

library.add(faFacebook, faFacebookMessenger, faFacebookF, faGooglePlus,
    faInstagram, faPinterest, faYoutube);

dom.watch();

const SocialFooter = () => (
    <div id="social" className="Social">
      <a target="_blank" href="https:/facebook.com/krakowskiepokoje" rel="noopener noreferrer">
        <FontAwesomeIcon className="AwesomeIcon" icon={["fab", "facebook-f"]} />
      </a>
      <a target="_blank" href="https://www.facebook.com/messages/t/krakowskiepokoje" rel="noopener noreferrer">
        <FontAwesomeIcon className="AwesomeIcon" icon={["fab", "facebook-messenger"]} />
      </a>
      <a target="_blank" href="https://www.instagram.com/krakowskiepokoje/" rel="noopener noreferrer">
        <FontAwesomeIcon className="AwesomeIcon" icon={["fab", "instagram"]} />
      </a>
      <a target="_blank" href="https://pl.pinterest.com/kpokoje/" rel="noopener noreferrer">
        <FontAwesomeIcon className="AwesomeIcon" icon={["fab", "pinterest"]} />
      </a>
      <a target="_blank" href="https://plus.google.com/u/4/113093600016565108053" rel="noopener noreferrer">
        <FontAwesomeIcon className="AwesomeIcon" icon={["fab", "google-plus"]} />
      </a>
      <a target="_blank" href="https://www.youtube.com/channel/UCMZoHpxpWJ8Cqe8rZxH3_Kg" rel="noopener noreferrer">
        <FontAwesomeIcon className="AwesomeIcon" icon={["fab", "youtube"]} />
      </a>
    </div>
);


export default SocialFooter;
