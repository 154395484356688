import React, {Component} from 'react';
import MenuList from "./MenuList";

class Navigation extends Component {
  render() {
    return (
        <div id="navigation" className="Navigation">
        <MenuList />
        </div>
    );
  }
};

export default Navigation;