import React, {Component} from 'react';
import './BackgroundSlider.css';

const image1 = {
  backgroundImage: 'url(./images/coffee-1284041.jpg)',
};

const image2 = {
  backgroundImage: 'url(./images/IMG_7647.jpg)',
};

const image3 = {
  backgroundImage: 'url(./images/flower-3157393.jpg)',
};


class BackgroundSlider extends Component {
  render() {
    return (
        <div id="carouselExampleIndicators" className="carousel slide"  data-ride="carousel">
          <ol className="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
          <div className="carousel-inner" role="listbox">
            <div className="carousel-item active" style={image1}>
              <div className="carousel-caption">
                <section className="wow fadeInUp" data-wow-delay="1.5s">
                  <div className="SlideCenterText">
                    <span>Poczuj się jak w domu</span>
                  </div>
                  <div className="SliderButtonHolder">
                    <a className="SliderButton" href="#offer" target="_self">Zobacz więcej</a>
                  </div>
                </section>
              </div>
            </div>
            <div className="carousel-item" style={image2}>
              <div className="carousel-caption">
                <section className="wow fadeInUp" data-wow-delay="1.5s">
                  <div className="SlideCenterText">
                    <span>Home Staging</span>
                  </div>
                  <div className="SliderButtonHolder">
                    <a className="SliderButton" href="#rooms" target="_self">Zobacz więcej</a>
                  </div>
                </section>
              </div>
            </div>
            <div className="carousel-item" style={image3}>
              <div className="carousel-caption">
                <section className="wow fadeInUp" data-wow-delay="1.5s">
                  <div className="SlideCenterText">
                    <span>Odpocznij, my pracujemy</span>
                  </div>
                  <div className="SliderButtonHolder">
                    <a className="SliderButton" href="#owner" target="_self">Zobacz więcej</a>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
    );
  }
};

export default BackgroundSlider
