import React from 'react';
import MessengerPlugin from 'react-messenger-customer-chat';


export default () => (
      <MessengerPlugin
          appId="2031463177111034"
          pageId="1510004172348749"
          language="pl_PL"
      />
);
