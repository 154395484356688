import React, {Component} from 'react';
import ReactSlider from 'react-slick';

class SliderImgCarusel extends Component {
  render() {
    const settings = {
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      infinite: true,
      responsive: [
        {
          breakpoint: 768, settings: { slidesToShow: 2 }
        },
      ],
    };
    return (
        <div id="" className="SlideCarusel">
          <ReactSlider {...settings}>
            <div className="SlideCaruselItem">
                <img className="img-fluid" src="./images/slider/1.jpg" alt="5" />
            </div>
            <div className="SlideCaruselItem">
                <img className="img-fluid" src="./images/slider/2.jpg" alt="2" />
            </div>
            <div className="SlideCaruselItem">
                <img className="img-fluid" src="./images/slider/3.jpg" alt="1" />
            </div>
            <div className="SlideCaruselItem">
                <img className="img-fluid" src="./images/slider/4.jpg" alt="5" />
            </div>
            <div className="SlideCaruselItem">
              <img className="img-fluid" src="./images/slider/5.jpg" alt="1" />
            </div>
          </ReactSlider>
        </div>
    );
  }
};

export default SliderImgCarusel;
