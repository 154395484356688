import React, {Component} from 'react';
import './Bar.css';
import Privacy from '../Privacy';


class Bar extends Component {
  render() {
    return (
      <div className="BarWrapper">
        <Privacy />
      </div>
    );
  }
};

export default Bar;
