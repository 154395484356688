import React from 'react';
import filter from 'lodash/filter';
import ReactSlider from 'react-slick';

export default class InstagramSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imagesTable: "",
      loaded: false,
      imageStatus: "loading",
      images: [],
      url: "krakowskiepokoje"

    };
  }

  componentDidMount() {
    let imagesTab = [];
    let imagesTable = [];
    fetch(`https://www.instagram.com/${this.state.url}`)
        .then(response => response.text())
        .then(responseText => {
          //let imagesRegex = /(?:"thumbnail_resources+":\[\{)(.*?)(\}\])/g;
          let imagesRegex = /(?:\{"node":\{"__typename":+)(.*?)("accessibility_caption":)(.*?)(\}\})/g;

          let imagesObject = responseText.match(imagesRegex);
          imagesObject.forEach(img => {
            imagesTable.push(JSON.parse(img));
          });

          imagesTable.forEach((val, i) => {
            let url = String(
                filter(
                    val["node"]["thumbnail_resources"],
                    {"config_width":320, "config_height":320}
                )[0]["src"]
            );
            let shortCode = String(val["node"]["shortcode"]);
            if (shortCode !== 'BqWubUdAOjG' && shortCode !== 'BpzHTFUA0bz') {
              imagesTab.push(
                  <div className="InstagramSlideCaruselItem">
                    <a href={`https://instagram.com/p/${shortCode}`}>
                      <img
                          className="img-fluid"
                          target={i}
                          alt={i}
                          // style={{height:150, width:150}}
                          src={url}
                      />
                    </a>
                  </div>
              )
            }
          });
          this.setState({
            images: imagesTab ? imagesTab : null,
            loaded: true,
          });
        })
        .catch(error => {});
  }


  render(){
    const {
      loaded,
      images
    } = this.state;

    const settings = {
      slidesToShow: 8,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      infinite: true,
      responsive: [
        {
          breakpoint: 1300, settings: { slidesToShow: 7 }
        },
        {
          breakpoint: 1200, settings: { slidesToShow: 6 }
        },
        {
          breakpoint: 1100, settings: { slidesToShow: 5 }
        },
        {
          breakpoint: 900, settings: { slidesToShow: 4 }
        },
        {
          breakpoint: 768, settings: { slidesToShow: 5 }
        },
        {
          breakpoint: 600, settings: { slidesToShow: 4 }
        },
        {
          breakpoint: 550, settings: { slidesToShow: 3 }
        },
        {
          breakpoint: 450, settings: { slidesToShow: 3 }
        },
        {
          breakpoint: 350, settings: { slidesToShow: 2 }
        },
      ],
    };

    let imgObj = '';

    if (loaded) {
      imgObj = images;
    }

    return (
        <div id="" className="InstagramSlideCarusel">
          <ReactSlider {...settings}>
            {imgObj}
          </ReactSlider>
        </div>
    )
  }
}
