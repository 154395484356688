import React, {Component} from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {faSearch, faSmile} from '@fortawesome/free-solid-svg-icons';
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import './OfferOwner.css';

library.add(faSearch, faSmile);

dom.watch();

const title = "ZADBAMY, BY TWOJE MIESZKANIE PRACOWAŁO DLA CIEBIE, A NIE TY DLA NIEGO";
const text = "Jeśli tak, to trafiłeś we właściwe miejsce.\n" +
      "Zaoszczędzimy Twój czas i pieniądze i przygotujemy Twoje mieszkanie do wynajmu.";




const footerTitle = "CZYM SIĘ ZAJMUJEMY?";
const footerText = "Zajmujemy się wynajmem mieszkań, zarządzaniem najmem, szukaniem i weryfikacją najemców, a także innowacyjnymi usługami homestagingowymi.\n" +
    "Wydobywamy na światło dzienne potencjał mieszkania, ponieważ nie każdy umie sobie wyobrazić jak dobrze może ono wyglądać, gdy się je odpowiednio zaaranżuje.\n" +
    "Ponadto oferujemy też usługi z zakresu pośrednictwa w obrocie nieruchomościami z nowoczesnym podejściem do prezentacji mieszkania i wyszukiwania klientów.\n" +
    "Dla inwestorów przygotowujemy tzw. gotowce inwestycyjne – wyszukujemy odpowiednie mieszkanie do kupna dla inwestora i przygotowujemy je potem do wynajmu.\n";

const consultationTitle = "ZAPRASZAMY NA KONSULTACJE ONLINE!";




class OfferOwner extends Component {
  render() {
    return (
        <div className="WrapperOfferOwner">
          <div className="OfferOwnerHeader">
            <section className="wow fadeInLeft">
              <header className="TextModule">
                <h2>
                  <span>{ title }</span>
                </h2>
                <p>
                  <ul>
                    <li>Chcesz wynająć mieszkanie, ale nie wiesz od czego zacząć?</li>
                    <li>Przeraża Cię ilość ogłoszeń w internecie dotyczących wynajmu i nie wiesz jak się wyróżnić wśród nich?</li>
                    <li>Chcesz znaleźć dobrych najemców, ale nie wiesz jak to zrobić i komu właściwie powinno być wynajmowane Twoje mieszkanie?</li>
                    <li>Chcesz zarabiać na swoim mieszkaniu, ale nie masz czasu na aranżowanie go?</li>
                  </ul>
                  { text }
                </p>
              </header>
            </section>
          </div>
          <div className="OfferOwnerMainContent">
            <div className="OfferOwnerColumnLeft">
              <section className="wow fadeInLeft">
                <div className="GridRow">
                  <div className="GridIcon">
                    <FontAwesomeIcon className="Icon" icon="home" />
                  </div>
                  <div className="TextModule">
                    <h3>ZAJMIEMY SIĘ WYNAJMEM TWOJEGO MIESZKANIA</h3>
                    <p>Pomożemy Ci znaleźć dobrych najemców i podpisać z nimi jasną i przejrzystą umowę, przygotujemy
                      regulamin użytkowania mieszkania, aby określić zasady współpracy między najemcami a właścicielem
                      oraz sporządzimy protokół odbioru nieruchomości wraz z dokumentacją fotograficzną.</p>
                  </div>
                </div>
                <div className="GridRow">
                  <div className="GridIcon">
                    <FontAwesomeIcon className="Icon" icon="search" />
                  </div>
                  <div className="TextModule">
                    <h3>POSTAWIMY DIAGNOZĘ I ODPOWIEMY NA PYTANIA</h3>
                    <p>Zdiagnozujemy dlaczego mieszkanie jeszcze się nie wynajęło i rozwiążemy ten problem. Chętnie
                      zajmiemy się homestagingiem i zdjęciami, które oddadzą klimat Twojego mieszkania. Jeśli to konieczne,
                      przeprowadzimy drobne naprawy. Udekorujemy całą przestrzeń i sfotografujemy.
                      Zadbamy o wszystkie detale i stworzymy przytulne wnętrze,
                      tak by przyszli najemcy czuli się jak w domu i chcieli troszczyć się o Twoje mieszkanie, tak jak Ty sam.</p>
                  </div>
                </div>
                <div className="GridRow">
                  <div className="GridIcon">
                    <FontAwesomeIcon className="Icon" icon="smile" />
                  </div>
                  <div className="TextModule">
                    <h3>A TY BĘDZIESZ MIAŁ W KOŃCU SPOKÓJ</h3>
                    <p>Pomożemy w zarządzaniu wynajmem, byś Ty mógł przeznaczyć swój wolny czas na ciekawsze zajęcia.
                      Możemy też wynająć od Ciebie mieszkanie, dzięki czemu będziesz mieć gwarancję czynszu przez okres przynajmniej 3 lat, niezależnie od pustostanów czy pogorszenia warunków rynkowych.
                      Po prostu daj nam znać jakim zakresem prac jesteś zainteresowany, a my zajmiemy się resztą.</p>
                  </div>
                </div>
              </section>
            </div>
            <div className="OfferOwnerColumnImageRight">
              <div className="Image">
                <section className="wow fadeInUp">
                  <img alt="" src="desktop_3.png" />
                </section>
              </div>
            </div>
          </div>
          <div className="OfferOwnerFooter">
            <section className="wow fadeInLeft">
              <footer className="TextModule">
                <h2>
                  <span>{ footerTitle }</span>
                </h2>
                <p>{ footerText }</p>
                <h2 id="consultation">
                  <span>{ consultationTitle }</span>
                </h2>
                <p>
                  <div className="TextModuleBox">
                    <span>Jeśli sam chcesz zająć się wynajmem, ale masz różne wątpliwości, to zapraszamy na konsultację online, w trakcie której dowiesz się:</span>
                    <ul>
                      <li>Co zmienić w swoim mieszkaniu, żeby ustawiła się do niego kolejka najemców.</li>
                      <li>Jak wyeksponować mocne strony mieszkania, a jego wady zniwelować lub przekuć na zalety.</li>
                      <li>Jakich błędów nie popełniać w trakcie robienia aranżacji mieszkania.</li>
                      <li>Jak nie przeinwestować.</li>
                      <li>Jakie dodatki wydobędą potencjał z Twojego mieszkania i gdzie je kupić.</li>
                    </ul>
                    <span>Zadzwoń do nas pod nr 691802254 lub napisz na biuro@krakowskiepokoje.pl aby ustalić dogodny termin. Więcej na temat konsultacji znajdziesz na stronie <a href="https://sklep.kreatorkiwynajmu.pl/produkt/konsultacja-home-staging-online-twoj-czas-na-zmiany/" target="_blank" rel="noopener noreferrer">sklep.kreatorkiwynajmu.pl</a></span>
                  </div>
                </p>
              </footer>
            </section>
          </div>
        </div>
    );
  }
};

export default OfferOwner;
