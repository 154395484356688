import React, {Component} from 'react';
import MenuList from './MenuList';
import SocialFooter from "./SocialFooter";
import Bar from "./Bar";


class Footer extends Component {
  render() {
    return (
        <footer className="FooterWrapper">
          <div className="FooterLogo">
            <section className="wow zoomIn">
              <span>Krakowskie Pokoje</span>
            </section>
          </div>
          <div className="Separator"></div>
          <div className="FooterMenu">
            <MenuList />
          </div>
          <div className="SocialFooter">
            <SocialFooter/>
          </div>
          <div className="FooterBar">
            <Bar />
          </div>
        </footer>
    );
  }
};

export default Footer;
