import React, {Component} from 'react';
import SectionTitle from '../SectionTitle';
import PhotoGallery from '../PhotoGallery';

const title = "Home Staging Kreatorki Wynajmu";
const alt = "Home Staging Kreatorki Wynajmu i Krakowskie Pokoje";
const subheading = "";

class RoomsGallery extends Component {
  render() {
    return (
        <div className="RoomsGalleryWrapper">
          <SectionTitle title={title} subheading={subheading} alt={alt}/>
          <PhotoGallery />
        </div>

    );
  }
};

export default RoomsGallery;
