import React, {Component} from 'react';

class MenuList extends Component {
  render() {
    return (
        <nav>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#about">O nas</a></li>
            <li><a href="#owner">Dla właścicieli</a></li>
            <li><a href="#homestaging">Home Staging</a></li>
            <li><a href="#consultation">Konsultacje</a></li>
            <li><a href="#offer">Dla najemców</a></li>
            <li><a href="http://klaudialeszczynska.pl/blog"  target="_blank" rel="noopener noreferrer">Blog</a></li>
            <li><a href="https://sklep.kreatorkiwynajmu.pl"  target="_blank" rel="noopener noreferrer">Sklep</a></li>
            <li><a href="#contact">Kontakt</a></li>
            {/*<li><a href="#partnership">Partnerzy</a></li>*/}
          </ul>
        </nav>
    );
  }
};

export default MenuList;
